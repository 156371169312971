import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { getRedirectLanguage } from '../utils/getRedirectLanguage';

const ContactPage = () => {
    useEffect(() => {
        const urlLang = getRedirectLanguage();

        navigate(`/${urlLang}/contact`);
    }, []);

    return null;
};

export default ContactPage;